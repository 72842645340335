import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAppDispatch, useAppSelector} from "../../../store/hooks.ts";
import {selectConfig, selectUser, setAuth, setUser} from "../../../store/auth.ts";
import axios from "axios";
import {useTranslation} from "react-i18next";
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import LoginOptions from "./LoginOptions.tsx";
import {setAuthUrl} from "../core/AuthHelpers.ts";
import TwoFactorAuth from "./TwoFactorAuth.tsx";
import i18n from "../../../../_mesomb/i18n";
import * as Sentry from "@sentry/react";
import {Helmet} from "react-helmet";

const loginSchema = Yup.object().shape({
  login: Yup.string()
    .email(i18n.t('Wrong email format'))
    .max(255, i18n.t('Minimum_characters', {min: 255}))
    .required(i18n.t('Field_is_required')),
  password: Yup.string()
    .max(200, i18n.t('Maximum_characters', {max: 200}))
    .required(i18n.t('Field_is_required')),
})

interface ILogin {
  login: string
  password: string
}

const initialValues: ILogin = {
  login: '',
  password: ''
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

function Login({onLogin}) {
  // const {saveAuth, setCurrentUser} = useAuth()
  const dispatch = useAppDispatch();
  const [mfa, setMfa] = useState(false)
  const {t} = useTranslation()
  const config = useAppSelector(selectConfig);
  const googleProvider =  config?.socialaccount?.providers?.find(p => p.id === 'google')
  const appleProvider =  config?.socialaccount?.providers?.find(p => p.id === 'apple')

  const finishLogin = async () => {
    const {data: profile} = await axios.get('profile/')
    dispatch(setUser(profile))
    location.href = '/'
  }

  const formik = useFormik<ILogin>({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting, setErrors}) => {
      setSubmitting(true)
      onLogin('login', {email: values.login, password: values.password}, setErrors).then(() => {
        setSubmitting(false)
      }).catch((error) => {
        setStatus(error.message)
        Sentry.captureException(error)
        setSubmitting(false)
      })
      /*axios.post(setAuthUrl('auth/login'), {email: values.login, password: values.password}).then(async ({data}) => {
        try {
          const {data: ret} = await axios.get(setAuthUrl('account/authenticators/totp'))
          if (ret.data.type) {
            setMfa(true)
          } else {
            finishLogin()
          }
        } catch (e) {
          finishLogin()
        }
      }).catch(error => {
        setSubmitting(false)
        const response = error.response.data;
        const errors = response.errors.reduce((acc, item) => ({...acc, [item.param]: item.message}), {})
        setErrors(errors)
      })*/
      // onLogin('password', {email: values.login, password: values.password}).then(() => {
      //   setSubmitting(false)
      //   // document.location.href = '/'
      // }).catch((error) => {
      //   setSubmitting(false)
      //   const response = error.response.data;
      //   const errors = response.errors.reduce((acc, item) => ({...acc, [item.param]: item.message}), {})
      //   setErrors(errors)
      // })
    },
  })

  if (mfa) {
    return (
      <TwoFactorAuth onSubmit={() => {
        finishLogin()
      }}/>
    )
  }

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-gray-900 fw-bolder mb-3'>{t('Sign In')}</h1>
          <div className='text-gray-500 fw-semibold fs-6'>{t('Welcome to MeSomb')}</div>
        </div>
        {/* begin::Heading */}

        {/* begin::Login options */}
        <LoginOptions
          googleClient={googleProvider?.client_id}
          appleClient={appleProvider}
          onLogin={onLogin}
        />
        {/* end::Login options */}

        {/* begin::Separator */}
        <div className='separator separator-content my-14'>
          <span className='w-200px text-gray-500 fw-semibold fs-7'>{t('Or with password')}</span>
        </div>
        {/* end::Separator */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-gray-900'>{t('Email')}</label>
          <input
            placeholder={t('Email address')}
            {...formik.getFieldProps('login')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.login && formik.errors.login},
              {
                'is-valid': formik.touched.login && !formik.errors.login,
              }
            )}
            type='email'
            name='login'
            // autoComplete='off'
          />
          {formik.touched.login && formik.errors.login && (
            <div className='fv-plugins-message-container form-text text-danger'>
              <span role='alert'>{formik.errors.login}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-gray-900 fs-6 mb-0'>{t('Password')}</label>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
            placeholder={t('Enter your password')}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div/>

          {/* begin::Link */}
          <Link to='/auth/forgot-password' className='link-primary'>
            {t('Forgot Password ?')}
          </Link>
          {/* end::Link */}
        </div>
        {/* end::Wrapper */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <ButtonIndicator
            label={t('Sign In')}
            type={'submit'}
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
          />
        </div>
        {/* end::Action */}

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          {t('Not a Member yet?')}{' '}
          <Link to='/auth/signup' className='link-primary'>
            {t('Sign up')}
          </Link>
        </div>
      </form>
      <Helmet>
        <title>{t('Authentication')}</title>
      </Helmet>
    </>
  )
}

export default Login;
