import React from 'react';
import {camelize} from "../../utils";
import {useTranslation} from "react-i18next";

export interface ButtonIndicatorProps {
  type: 'button' | 'submit' | 'reset';
  label: string;
  indicator?: string;
  loading?: boolean;
  width?: number;
  disabled?: boolean;
  marginTop?: number;
  onClick?: () => void;
}

function ButtonIndicator({type, label, indicator, loading = false, width = 100, disabled = false, marginTop = 5, ...props} : ButtonIndicatorProps) {
  const {t} = useTranslation()
  return (
    <button
      type={type}
      className={`btn btn-primary min-w-${width}px mt-${marginTop}`}
      disabled={disabled}
      {...props}
    >
      {!loading && <span className="indicator-label">{label}</span>}
      {loading && (
        <span className="indicator-progress" style={{display: 'block'}}>{indicator || t('Please wait')}...
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      )}
    </button>
  );
}

export default ButtonIndicator;