import React from 'react';
import {Field} from "formik";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {extract} from "../../utils";

function ContactField({name, errors, touched, readOnly = false}) {
  return (
    <div
      className={clsx('form-control form-control-lg p-0 mb-4', {'is-invalid': extract(touched, name) && extract(errors, name)},
        {
          'is-valid': extract(touched, name) && !extract(errors, name),
        })}>
      <div className="d-flex">
        <Field
          // id={`id_${name}`}
          type={'email'}
          name={`${name}.email`}
          placeholder={'contact@example.com'}
          className={clsx(
            `form-control form-control-transparent`,
          )}
          readOnly={readOnly}
        />
        <Field
          // id={`id_${name}`}
          type={'phone'}
          name={`${name}.phone_number`}
          placeholder={'670000000'}
          className={clsx(
            `form-control form-control-transparent`,
          )}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}

export default ContactField;
