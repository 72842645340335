import React, {useRef, useState} from 'react';
import clsx from "clsx";

function OtpInput({name, form, isSecure = false, length = 4, inputType = 'numeric', onComplete}) {
  const [values, setValues] = useState(Array.from({length}).map(() => ''));
  const inputs: Record<string, any> = {};
  return (
    <div id={`id_${name}`} className={clsx('d-flex flex-wrap', {'is-invalid': form.touched[name] && form.errors[name]}, {'is-valid': form.touched[name] && !form.errors[name],})}>
      {values.map((v, index) => (
        <input
          key={`input_${index}`}
          ref={(input) => {
            inputs[`input_${index}`] = input;
          }}
          type={isSecure ? 'password' : 'text'}
          inputMode={inputType == 'numeric' ? 'numeric' : 'text'}
          name={`${name}_${index}`}
          maxLength={1}
          required={true}
          value={v}
          className={clsx('form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2')}
          onKeyUp={(e) => {
            const check = inputType === 'numeric' && /^[0-9]$/.test(e.key) || inputType === 'alphanumeric' && /^[a-zA-Z0-9]$/.test(e.key);
            if (['Backspace'].includes(e.key) || check) {
              if (e.key === 'Backspace') {
                values[index] = '';
                if (index > 0) {
                  inputs[`input_${index - 1}`].focus();
                } else {
                  // inputs[`input_${index}`].blur();
                  form.setFieldValue(name, values.join(''));
                }
              } else if (check) {
                values[index] = e.key;
                if (index < length - 1) {
                  inputs[`input_${index + 1}`].focus();
                } else {
                  inputs[`input_${index}`].blur();
                  form.setFieldValue(name, values.join(''));
                }
              }
              setValues([...values]);
              // inputs[`input_${index + 1}`]?.focus();
            }
          }}
          onFocus={() => form.setFieldTouched(name, true)}
        />
      ))}
    </div>
  );
}

export default OtpInput;
